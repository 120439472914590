import React from "react";
import { MWDStoreLink } from "../components/storeLinks";
import Layout from "../components/layout";

import i1 from "../img/app/1.png";
import i2 from "../img/app/2.png";
import i3 from "../img/app/3.png";
import i4 from "../img/app/4.png";
import i5 from "../img/app/5.png";
import i6 from "../img/app/6.png";
import i7 from "../img/app/7.png";
import i8 from "../img/app/8.png";
import i9 from "../img/app/9.png";

export default () => (
  <Layout>
    <div className="container">
      <h1>Design lightweight modern websites in minutes</h1>

      <p>Choose a project or create a new one.</p>

      <div className="image-container">
        <img src={i1} alt="Menu" />
      </div>

      <p>
        Give it a name and subtitle. This is clearly the most important part.
        Make sure it expresses your originality and depth of thought in a
        whimsical manner.{" "}
      </p>

      <div className="image-container">
        <img src={i2} alt="a project" />
      </div>
      <p>
        Now choose some colours. You probably aren't very good at this. Not to
        worry, the App suggests choices based on harmonious colour
        relationships.
      </p>

      <div className="image-container">
        <img src={i3} alt="Choose colours" />
      </div>
      <p>
        The App includes a touchscreen first, colour pickers that makes sense
        for humans. No hexadecimal RGB BS. First pick a hue (what colour), then
        saturation (how colourful) and then value.
      </p>

      <div className="image-container">
        <img src={i4} alt="touchscreen first colour picker" />
      </div>

      <p>
        On to fonts. Pick a pair of fonts from a wide selection from Google Web
        Fonts. When you export (more on that later) links will be included in
        the CSS. No need to host anything.
      </p>

      <div className="image-container">
        <img src={i5} alt="Pick fonts" />
      </div>
      <p>
        Try not to completely mess this up. Some of these fonts are pretty ugly.
      </p>

      <div className="image-container">
        <img src={i7} alt="Choose a font" />
      </div>
      <p>
        You can configure the sizings and spacings from simple rules. Just pick
        a few options and ratios and the App will do the rest.
      </p>

      <div className="image-container">
        <img src={i6} alt="Choose spacings and sizes" />
      </div>
      <p>
        Preview how it will look on a variety of devices. It is{" "}
        {new Date().getFullYear()}, you better make sure your design works on
        mobile and desktop.
      </p>

      <div className="image-container">
        <img src={i8} alt="Preview, with different device frames" />
      </div>
      <p>
        Now let's export. There are a bunch of formats. A single page, which is
        easy to share. A HTML and CSS combo which is ready to go. Or SCSS if you
        know what that is. And if you are a developer why not export JSON? The
        exports have small fast-loading, modern styling and example HTML. It
        will be sufficient for a blog, but is easy to adapt and extend for more
        substantial projects.
      </p>
      <div className="image-container">
        <img src={i9} alt="Choose colours" />
      </div>

      <p>
        That was quick. You should probably mindlessly check your social media
        feeds for a bit. And don't tell your boss how easy your job now is.
      </p>

      <MWDStoreLink />
    </div>
  </Layout>
);
